<template>
  <v-card class="pa-0">
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>All Notice Board </v-card-title>
      <v-spacer> </v-spacer>

      <v-dialog v-model="dialogs" persistent max-width="50%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"> Add New </v-btn>
        </template>
        <v-card>
          <v-toolbar width="100%" class="mx-0" color="primary " dark>
            <v-card-text class="text-h5"> New Notice Board </v-card-text>
            <v-spacer> </v-spacer>
          </v-toolbar>
          <v-form ref="form">
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="selected_class"
                    item-text="room_name"
                    item-value="room_id"
                    v-model="room"
                    label="Class Room"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-card-text>
              <v-text-field
                v-model="title"
                :rules="$requiredRules"
                label="Title*"
              ></v-text-field>

              <v-textarea
                rows="5"
                v-model="content"
                :rules="$requiredRules"
                label="Content*"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="save"> Save </v-btn>

              <v-btn color="primary" @click="cancel_dialog"> cancel </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-row
      style="
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border: 1px solid;
        margin-bottom: 10px;
      "
    >
      <v-col>
        <v-autocomplete
          v-model="selectedClassroom"
          :items="selected_class"
          label="Filter by Classroom"
          :search-input.sync="searchClassroom"
          @change="filterByClassroom"
          item-text="room_name"
          item-value="room_id"
          style="font-size: large"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <div>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="start_date"
                label="Start Date"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="end_date"
                label="End Date"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <DataTableSSR
      apiEndPoint="notice/"
      :headers="headers"
      :instantLoad="true"
      :key="refreshKey"
      :items="notice"
      :queryParams="{
        classroom: selectedClassroom,
        start_date: start_date,
        end_date: end_date,
      }"
    >
      <template #[`item.show`]="{ item }">
        <v-dialog v-model="item.dialognotice" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-input
              v-bind="attrs"
              v-on="on"
              id="title"
              color="black"
              v-model="closeTitle"
            >
              {{ item.title.substring(0, 20) + ".." }}
            </v-input>
          </template>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="12" md="8" lg="11">
                  <span class="text-h5">Notice Details</span>
                </v-col>
                <v-col cols="12" md="4" lg="1" ml="14" pl="2">
                  <v-btn icon @click="item.dialognotice = false">
                    <v-icon large color="red lighten-2"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-card>
                <v-toolbar color="primary" dark dense flat>
                  <v-toolbar-title class="text-body-2">
                    {{ item.title }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  {{ item.content }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-dialog max-width="700px" v-model="item.dialogNewFee">
          <template v-slot:activator="{ attrs, on }">
            <v-row>
              <v-btn
                v-bind="attrs"
                :color="item.is_active ? 'primary' : 'warning'"
                :disabled="!item.is_active"
                class="ma-4"
                v-on="on"
                outlined
              >
                Edit
              </v-btn>
              <v-switch
                depressed
                :input-value="item.is_active"
                @change="toggleActive(item)"
              ></v-switch>
            </v-row>
          </template>
          <notice-update
            :item="item"
            :loading="loading"
            v-on:update-data="updateData"
            @closeDialog="item.dialogNewFee = false"
            @reFreshData="reFreshData"
          ></notice-update>
        </v-dialog>
      </template>
    </DataTableSSR>
  </v-card>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
import NoticeUpdate from "./NoitceUpdate.vue";
import RoomAutoComplete from "@/components/RoomAutoComplete.vue";

export default {
  components: { DataTableSSR, NoticeUpdate, RoomAutoComplete },
  props: ["item", "class_id"],
  form: "",
  data: () => ({
    dialognotice: false,
    notice: [],
    loading: false,
    selectedClassroom: [],
    refreshKey: 0,
    start_date: null,
    end_date: null,
    headers: [
      {
        text: "Title",
        value: "show",
        hover: "yellow",
      },
      {
        text: "Created",
        value: "created_at",
      },
      {
        text: "Action",
        value: "action",
      },
    ],
    closeTitle: false,
    dialogNewFee: false,
    show: false,
    is_active: false,
    items: [],
    rooms: [],
    room: "",
    selected_class: [],

    dialog: false,
    closedialog: false,
    dialogs: false,
    closeDialogNewFee: false,
    timer: "",
    noticeData: [],
  }),

  mounted() {
    this.fetchNotice();

    this.loadRooms();
    // this.loadOwnedRooms();
  },
  created() {
    this.fetchData();
  },

  emits: ["closeDialog", "reFreshData"],
  computed: {
    rowClasses() {
      return (item) => ({
        "active-row": item.is_active,
        "inactive-row": !item.is_active,
      });
    },
  },

  methods: {
    fetchData() {
      this.$api.put(`/notice/`).then((res) => {
        notice = res.data;
      });
    },
    changeMenu() {
      this.$api.put(`/notice/${this.item.id}`, this.notice).then((res) => {
        notice = res.data;
      });
    },
    fetchNotice() {
      this.$api
        .get(`/notice/`)
        .then((response) => {
          this.noticeData = response.data.results;
        })
        .then(() => {
          this.refreshKey += 1;
          this.resetValues();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reFreshData() {
      this.fetchNotice();
    },
    // loadOwnedRooms() {
    //   return this.$api.get("/rooms/").then((res) => {
    //     this.rooms = res.data.results;
    //   });
    // },
    loadRooms() {
      return this.$api
        .get("/class_rooms/")
        .then((res) => {
          this.selected_class = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancel() {
      this.dialognotice = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        const data = {
          title: this.title,
          content: this.content,
          rooms: this.room,
        };
        return this.$api.post("/notice/", data).then(() => {
          this.dialogs = false;
          this.refreshKey += 1;
          this.resetValues();
        });
      }
    },
    resetValues() {
      this.title = null;
      this.content = null;
      this.rooms = null;
    },

    cancel_dialog() {
      this.dialogs = false;
    },

    closeDialog() {
      this.dialogNewFee = false;
    },

    closeDialogNewFee() {
      this.closeDialogNewFee = false;
    },

    toggleActive(item) {
      const newActiveState = !item.is_active;
      this.$api
        .put(`/notice/${item.id}/`, {
          is_active: newActiveState,
          title: item.title,
          content: item.content,
          rooms: item.rooms,
        })
        .then((response) => {
          item.is_active = response.data.is_active;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    filterByClassroom() {
      this.$refs.datatableSSR.refresh();
    },
    updateData(newData) {
      this.notice = newData;
    },
  },
};
</script>

<style></style>